import { EnvironmentCluster } from '../../types';
const getStatus = (environment) => {
    const workflow = getEnvironmentWorkflow(environment);
    if (workflow?.status === 'completed' && hasExpired(environment)) {
        return 'expired';
    }
    if (workflow) {
        return workflow.status || 'active';
    }
    return environment.metadata.packages.length > 0 ? 'completed' : 'failed';
};
const buildEnvironmentUrl = (studyTest, app = 'apps-dashboard') => {
    const cortexweb = studyTest.cortexURL.replace('api', app);
    return `${cortexweb}/?orgCode=${studyTest.code}`;
};
const getEnvironmentWorkflow = (environment) => {
    return environment.workflows[0];
};
const isTimeTravelEnvironment = (environment) => {
    return environment.cluster === EnvironmentCluster.EPHEMERAL;
};
const hasExpired = (environment) => {
    if (isTimeTravelEnvironment(environment) && environment.metadata.expiresAt) {
        return environment.metadata.expiresAt < new Date().toISOString();
    }
    return false;
};
export const DeploymentUtils = {
    buildEnvironmentUrl,
    getEnvironmentWorkflow,
    getStatus,
    hasExpired,
    isTimeTravelEnvironment,
};
