import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClientContext } from '@tanstack/react-query';
import { useContext, useMemo, useState } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { RadioGroup, RadioGroupOption } from 'libs.nucleus.radio_group';
import { Select } from 'libs.nucleus.select';
import { Spinner } from 'libs.nucleus.spinner';
import { TextArea } from 'libs.nucleus.text_area';
import { AuthContext } from 'libs.react.auth';
import { useApiClient } from 'libs.react.contexts';
import { useToastNotification } from 'libs.react.hooks';
import { ErrorUtils } from 'libs.react.utils';
import { StudyConfigurationContext } from '../../contexts';
import { useDeploymentUtils, useJobChecker } from '../../hooks';
import { EnvironmentCluster } from '../../types';
import { generateRandomOrgName } from '../../utils';
const DEFAULT_STUDY_TEST_FORM = {
    name: '',
    description: '',
    isEphemeral: false,
    region: { label: '-select-', value: '' },
    version: { label: '-select-', value: '' },
};
export const TestModal = ({ onClose, onSave, regionOptions, versions, initialTest }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { checkJobStatus } = useJobChecker();
    const { getFullRegionName } = useDeploymentUtils();
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const { logEvent } = useContext(FirebaseAppContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const { entityId } = useContext(AuthContext);
    const queryClient = useContext(QueryClientContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isPristine, setIsPristine] = useState(true);
    const [studyTestForm, setStudyTestForm] = useState({
        ...DEFAULT_STUDY_TEST_FORM,
        name: initialTest?.metadata.name || '',
        description: initialTest?.metadata.description || '',
        region: initialTest
            ? { label: getFullRegionName(initialTest.region), value: initialTest.cluster }
            : DEFAULT_STUDY_TEST_FORM.region,
    });
    const saveDisabled = isPristine ||
        (initialTest
            ? !studyTestForm.version.value
            : !studyTestForm.name ||
                !studyTestForm.version.value ||
                (!studyTestForm.isEphemeral && !studyTestForm.region.value));
    const versionOptions = useMemo(() => versions.map((version) => ({
        label: translate(`${version.metadata.name} (${version.externalVersion})`),
        value: version.id,
    })), [versions]);
    const getOrgCode = () => {
        const env = studyTestForm.isEphemeral
            ? EnvironmentCluster.EPHEMERAL
            : studyTestForm.region.value;
        return generateRandomOrgName(env);
    };
    const saveTest = async () => {
        if (!saveDisabled) {
            try {
                setIsLoading(true);
                let endpoint = `/v1/entities/${entityId}/studies/${studyId}/deploy`;
                const options = {
                    studyConfigId: studyTestForm.version.value,
                    metadata: {
                        description: studyTestForm.description,
                        name: studyTestForm.name,
                    },
                };
                if (initialTest) {
                    options.environmentId = initialTest.id;
                }
                else {
                    endpoint += '?autoProvision=true';
                    options.cortexEnv = getOrgCode();
                }
                const { data: response } = await momClient.post(endpoint, options);
                checkJobStatus(response.data.jobId, {
                    interval: 3000,
                    onInterval: (jobId, response, endPolling) => handleEnvironmentCreation(jobId, response, endPolling),
                });
                logEvent(initialTest ? 'test_sandbox_updated' : 'test_sandbox_created', {
                    study_id: studyId,
                    test_sandbox_name: studyTestForm.name,
                    test_sandbox_version: studyTestForm.version.label,
                    test_sandbox_description: studyTestForm.description,
                });
            }
            catch (error) {
                console.error('Error deploying study configuration', error);
                onDeployError();
            }
        }
    };
    const invalidateEnvironments = async () => {
        await queryClient?.invalidateQueries({ queryKey: ['entities', entityId, 'studies', studyId, 'environments'] });
    };
    /**
     * We validate the job til the environment is created, after that is when the deployment starts
     */
    const handleEnvironmentCreation = (jobId, response, endPolling) => {
        const operationType = initialTest ? 'deployments.deploy' : 'environment.create';
        const environmentJob = response.operations.find((operation) => operation.type === operationType);
        if (environmentJob && environmentJob.status === 'completed') {
            onSave(jobId);
            invalidateEnvironments();
            endPolling();
            addNotification({
                title: translate('Test version is being deployed. Hang tight, this might take a bit! You can navigate away from this page'),
                type: 'info',
            });
        }
        if (response.status === 'failed') {
            onDeployError(ErrorUtils.getResultErrorMessage(response.result));
            endPolling();
            logEvent('study_deploy_failed', { study_id: studyId });
        }
    };
    const onDeployError = (message) => {
        const subtitle = message
            ? translate('The deployment of this version of your study failed because of "{message}"', { message })
            : translate('The deployment of this version of your study failed. Please try again.');
        addNotification({ title: translate('Error deploying study'), subtitle, type: 'error' });
        setIsLoading(false);
    };
    const savePrimaryButton = {
        label: initialTest ? translate('Update') : translate('Create'),
        onClick: saveTest,
        disabled: saveDisabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            saveTest();
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        saveTest();
    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setStudyTestForm((oldForm) => ({ ...oldForm, [id]: value }));
        setIsPristine(false);
    };
    const handleEphemeralChange = (newEphemeral) => {
        setStudyTestForm((oldForm) => ({ ...oldForm, isEphemeral: newEphemeral }));
        setIsPristine(false);
    };
    const handleVersionChange = (newVersion) => {
        setStudyTestForm((oldForm) => ({ ...oldForm, version: newVersion }));
        setIsPristine(false);
    };
    const handleRegionChange = (newRegion) => {
        setStudyTestForm((oldForm) => ({ ...oldForm, region: newRegion }));
        setIsPristine(false);
    };
    const formSections = [
        {
            title: translate('Details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6 max-w-input-lg', children: [_jsx(Input, { id: 'name', label: translate('Sandbox name'), maxLength: 50, onChange: handleInputChange, required: true, value: studyTestForm.name, width: 'full', autoComplete: 'off', disabled: !!initialTest, containLabels: true }), _jsx(TextArea, { id: 'description', label: translate('Description'), onChange: handleInputChange, value: studyTestForm.description, width: 'full', disabled: !!initialTest })] })),
        },
        {
            title: translate('Configuration'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6 max-w-input-lg', children: [!initialTest && (_jsxs(RadioGroup, { dataTestId: 'create-test-ephemeral', label: translate('Type of sandbox'), onChange: handleEphemeralChange, value: studyTestForm.isEphemeral, children: [_jsx(RadioGroupOption, { option: {
                                    value: false,
                                    label: translate('Standard'),
                                    description: translate('General environment for development, testing, and training'),
                                } }), _jsx(RadioGroupOption, { option: {
                                    value: true,
                                    label: translate('Time travel'),
                                    description: translate('Simulated time environment for testing date-dependent processes and behaviors'),
                                } })] })), !studyTestForm.isEphemeral && (_jsx(Select, { dataTestId: 'create-test-region', label: translate('Region'), description: translate('Select the region where you want to deploy the sandbox environment'), onChange: handleRegionChange, options: regionOptions, required: true, value: studyTestForm.region, width: 'full', disabled: !regionOptions.length || !!initialTest })), _jsx(Select, { dataTestId: 'create-test-version', label: translate('Version'), description: translate('Select the version of the build you want to deploy to the sandbox'), onChange: handleVersionChange, options: versionOptions, required: true, value: studyTestForm.version, width: 'full' })] })),
        },
    ];
    return (_jsxs(ModalWindow, { title: initialTest ? translate('Update test environment') : translate('Create test environment'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) })] }));
};
